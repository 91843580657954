import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import Nav from "./components/Nav";
import Loading from "./components/Loading";

import './App.css';

import Public from './pages/Public';
import Home from './pages/Home';
import Todo from './pages/Todo';
import Poll from './pages/Poll';
import Profile from "./pages/Profile";
import ContentReview from "./pages/ContentReview";

import ProtectedRoute from "./auth/ProtectedRoute";

function App() {
	const { isLoading } = useAuth0();

	if (isLoading) {
    return <Loading />;
  }

	return (
		<Switch>
			<Route path="/" exact component={Public}/>
			<ProtectedRoute path="/todo" component={Todo} />
			<ProtectedRoute path="/profile" component={Profile} />
			<ProtectedRoute path="/poll" component={Poll} />
			<ProtectedRoute path="/content-review/*" component={ContentReview} />
		</Switch>
	);
}

export default App;