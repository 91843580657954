import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const PublicNav = (children) => {
	const { isAuthenticated } = useAuth0();

	return (
		<div className="dropdown">
			<a href="#" className="d-flex align-items-center col-lg-4 mb-2 mb-lg-0 link-dark text-decoration-none dropdown-toggle" id="dropdownNavLink" data-bs-toggle="dropdown" aria-expanded="false">
				<svg className="bi me-2" width="40" height="32"><use xlinkHref="#bootstrap"/></svg>
			</a>
			<ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownNavLink">
				<li><a className="dropdown-item active" href="#" aria-current="page">Overview</a></li>
				<li><a className="dropdown-item" href="#">Ratings</a></li>
				{isAuthenticated ? 
				<>
					<li><hr className="dropdown-divider" /></li>
					<li><a className="dropdown-item" href="/todo">Todo</a></li>
				</> : <></>
				}
			</ul>
		</div>
)}

export default PublicNav;