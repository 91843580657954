import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

import AsyncCreatableSelect from 'react-select/async-creatable';


export const Todo = ({ todo, setTodos, getAuthorOptions, getPublisherOptions, setAuthorOptions, setPublisherOptions, authorOptions, publisherOptions, ...extra }) => {
	const [edit, setEdit] = useState(false);
	const [updatedTodo, setUpdatedTodo] = useState({});

	const [draftTodoValue, setDraftTodoValue] = useState({
		author: todo.author,
		publisher: todo.publisher,
		uri: todo.uri,
		content: todo.content ? todo.content : ""
	});

	const { getAccessTokenSilently } = useAuth0();

	//data to be transformed; type is data type; reactSelect is TRUE/FALSE on which format to use - for TRUE use {value: *, label: *}
	const formatTodoComponent = (data, type, reactSelect) => {
		switch(type){
			case "author":
				return reactSelect ? data.map( ({_id, name}) => ({
					value: _id,
					label: name
				})) : data.map(({value, label}) => ({
					_id: value,
					name: label
				}) );
			break;
			case "publisher":
				return reactSelect ? {
					value: data._id,
					label: data.name
				} : {
					_id: data.value,
					name: data.label
				};
			break;
			case "quality":
				console.log("format todo function not configured");
			break;
		}
	}

	const handleEdit = async () => {
    setEdit(!edit);
  }
	const handleCancel = async () => {		
		await handleReset();
		await handleEdit();
	}
	const handleReset = async () => {	
		await setDraftTodoValue({ ...todo});
		return(true);
	}
	const handleChange = (e) => {
		e.preventDefault();
		setDraftTodoValue({...draftTodoValue, [e.target.name]: e.target.value});
	}

	const handleAuthorChange = async (changedInput, {action, option, removedValue, name}) => {
		const token = await getAccessTokenSilently();
		switch(action){
			case 'create-option':
				await axios.post(process.env.REACT_APP_SERVER_URL+'/api/authors/add', { name: option.label }, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
					.then(async ({data}) =>  {
						let selectObject = { value: data._id, label: data.name };
						setAuthorOptions( o => [...o, selectObject] );
						setDraftTodoValue( o => ({ ...draftTodoValue,
							author: [...o, formatTodoComponent(data,"author",false)]
						}) );
					})
					.catch( error => {
						console.log("There was an error handling the Author creation: ", error);
					});
				break;
			case 'select-option':
			case 'deselect-option':
			case 'remove-value':
			case 'clear':	
			case 'pop-value':
				console.log(changedInput)
				await setDraftTodoValue( (o) => ({...draftTodoValue, 
					author: formatTodoComponent(changedInput,"author",false)
				}) );
				break;
			case 'set-value':
				break;
		}
	}
	const handlePublisherChange = async (changedInput, {action, option, removedValue, name}) => {
		const token = await getAccessTokenSilently();
		switch(action){
			case 'create-option':
				await axios.post(process.env.REACT_APP_SERVER_URL+'/api/publishers/add', { name: option.label }, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
					.then(({data}) =>  {
						let selectObject = { value: data._id, label: data.name };
						setPublisherOptions( o => [...o, selectObject] );
						setDraftTodoValue( o => ({...draftTodoValue,
							publisher: [formatTodoComponent(data,"publisher",false)]
						}) );
					})
					.catch( error => {
						console.log("There was an error handling the Publisher creation: ", error);
					});
				break;
			case 'select-option':
			case 'deselect-option':
			case 'remove-value':
			case 'clear':	
			case 'pop-value':
				await setDraftTodoValue( (o) => ({...draftTodoValue,
					publisher: formatTodoComponent(changedInput,"publisher",false)
				}) );
				break;
			case 'set-value':
				break;
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		const token = await getAccessTokenSilently();
		axios.post(process.env.REACT_APP_SERVER_URL+'/api/articles/edit/'+todo._id, draftTodoValue, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then( ({data}) => {
				handleEdit();
				setTodos(oldTodos => {
					let newTodos = oldTodos.map((todo, index) => {
						if(todo._id == data._id){
							return data;
						} else {
							return todo;
						}
					})

					return newTodos;
				});
			})
			.catch(error => {
				console.log("There was an error saving the item: ", error)
			});
	}
	
	const authorReducer = (accumulator, currentValue, index) => index > 0 ? accumulator.concat(", ", currentValue.name) : currentValue.name;

	return (
		<div className="card mb-3 bt-3" {...extra}>
		{!edit ? (
			<>
			<div className="card-body">
				<h5 className="card-title">{todo.title ? todo.title : ""}</h5>
				<h5 className="card-title">{todo._id}</h5>
				<p className="card-text">Author: {todo.author && todo.author.length > 0 ? todo.author.reduce(authorReducer, "") : ""}</p>
				<p className="card-text">Publisher: {todo.publisher && todo.publisher.name ? todo.publisher.name : ""}</p>
				<a href={todo.uri} className="btn btn-primary" target="_blank">Open</a>
				<div>{todo.content.length > 0 ? (
					<button type="button" className="btn btn-warning" onClick={() => window.open("/content-review/"+todo._id)}>Rate</button>
				) : (
					<button type="button" className="btn btn-warning" onClick={handleEdit}>Edit</button>
				)}
				</div>
				<div className="footer">
					<small>Added on {todo.pubDate}</small>
				</div>
			</div>
			</>
			) : (
				<><form onSubmit={handleSubmit}>
					<h5 className="card-title">{todo.title}</h5>
					<div className="input-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text" id="addon-1">Author(s)</span>
						</div>
						<div className="react-select form-control p-0">
							<AsyncCreatableSelect 
								isMulti
								placeholder="Type something and press enter..."
								defaultOptions={ authorOptions }
								onChange={ handleAuthorChange }
								value={( formatTodoComponent(draftTodoValue.author,"author",true) )}
							/>
						</div>
					</div>
					<div className="input-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text" id="addon-2">Publisher</span>
						</div>
						<div className="react-select form-control p-0">
							<AsyncCreatableSelect 
								placeholder="Type something and press enter..."
								defaultOptions={ publisherOptions }
								onChange={ handlePublisherChange }
								value={ formatTodoComponent(draftTodoValue.publisher,"publisher",true) }
							/>
						</div>
					</div>
					<div className="input-group mb-3">
						<a href={todo.uri} target="_blank" className="btn btn-info">Go to article.</a>
					</div>
					<div className="input-group mb-3">
						<div className="input-group-prepend">
							<label className="input-group-text" htmlFor="contentTextarea">Content</label>
						</div>
						<textarea className="form-control" id="contentTextarea" rows="3" name="content" value={draftTodoValue.content.length > 0 ? draftTodoValue.content : ""} onChange={handleChange}></textarea>
					</div>

					<div className="d-flex text-center justify-content-between">
						<div className="">
							<button type="button" className="btn btn-danger" onClick={handleCancel}>Cancel</button>
							<button type="button" className="btn btn-warning" onClick={handleReset}>Reset</button>
						</div>
						<button type="submit" className="btn btn-primary">Save</button>
						<button type="button" className="btn btn-primary" onClick={ () => {
							console.log(draftTodoValue);
						} }>Console Draft Todo</button>
					</div>
				</form></>
			)
		}
		</div>
	);
}

const TodoList = ({ todos, setTodos }) => {
	const [authorOptions, setAuthorOptions] = useState([]);
	const [publisherOptions, setPublisherOptions] = useState([]);
	const { getAccessTokenSilently } = useAuth0();

	//loads the options initially
	useEffect( () => {
		const initAuthorOptions = async () => {
			const options = await getAuthorOptions("");
			setAuthorOptions(options);
		}
		const initPublisherOptions = async () => {
			const options = await getPublisherOptions("");
			setPublisherOptions(options);
		}
		initAuthorOptions();
		initPublisherOptions();
	}, []);


	const getAuthorOptions = async (input) => {
		const token = await getAccessTokenSilently();
		return axios.get(process.env.REACT_APP_SERVER_URL+'/api/authors/'+input, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(async ({data}) => {
				return data.map( ({_id, name}) => ({ value: _id, label: name }) );
			})
			.catch( error => {
				console.log(error);
			});
	}
	const getPublisherOptions = async (input) => {
		const token = await getAccessTokenSilently();
		return axios.get(process.env.REACT_APP_SERVER_URL+'/api/publishers/'+input, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(async ({data}) => {
				return data.map( ({_id, name}) => ({ value: _id, label: name }) );
			})
			.catch( error => {
				console.log(error);
			});
	}


	return (
  <>
    {(todos || []).map((todo, index) => (
      <Todo key={index} todo={todo} setTodos={setTodos} getAuthorOptions={getAuthorOptions} getPublisherOptions={getPublisherOptions} setAuthorOptions={setAuthorOptions} setPublisherOptions={setPublisherOptions} authorOptions={authorOptions} publisherOptions={publisherOptions} />
    ))}
  </>
)};

export default TodoList;