import React from 'react';
import { Link } from '@reach/router';

const Footer = () => {
	return (	
		<>
			<footer className="footer mt-auto py-3 bg-light">
				<div className="container">
					<span className="text-muted">Copyright © {new Date().getFullYear()}</span>
				</div>
			</footer>
		</>
	);
}

export default Footer;