//https://auth0.com/blog/complete-guide-to-react-user-authentication/
import React from "react";

import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";

import { useAuth0 } from "@auth0/auth0-react";

const AuthNav = () => {
	const { isAuthenticated } = useAuth0();

	return isAuthenticated ? (
		<div className="flex-shrink-0 dropdown">
			<a href="#" className="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
				<img src="https://github.com/mdo.png" alt="mdo" width="32" height="32" className="rounded-circle" />
			</a>
			<ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser">
				<li><a className="dropdown-item" href="#">Profile</a></li>
				<li><hr className="dropdown-divider" /></li>
				<li><LogoutButton /></li>
			</ul>
		</div>
	) : <LoginButton />
}

export default AuthNav;