//https://github.com/auth0-blog/auth0-react-sample-classes/blob/main/src/components/loading.js

import React from "react";
const loadingImg =
  "https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg";

class Loading extends React.Component {
  render() {
    return (
      <div className="spinner">
        <img src={loadingImg} alt="Loading..." />
      </div>
    );
  }
}

export default Loading;