//pending articles to assess or assign to publisher and/or author
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

import Layout from '../components/Layout';
import TodoList from '../components/Todo';

const Todo = () => {
	const [todos, setTodos] = useState([]);
	const { getAccessTokenSilently } = useAuth0();
	
	const updateTodos = (content) => {
		console.log("this is an update: ", content)
		setTodos(content)
	};

	useEffect( () => { 
		async function fetchTodos(){
			const token = await getAccessTokenSilently();
			const getTodos = await axios.get(process.env.REACT_APP_SERVER_URL+'/api/todos/', {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			
			setTodos(getTodos.data);
		}
		fetchTodos();
	}, [])

  return (
    <Layout>
      <h1>Content Todos</h1>
			<>
				<TodoList todos={todos.filter(item => !(item.content.length > 0) )} setTodos={updateTodos} />
			</>
			<h1>Rating Todos</h1>
			<>
				<TodoList todos={todos.filter(item => item.content.length > 0 )} setTodos={updateTodos} />
			</>
    </Layout>
  )
}

export default Todo;