import React from 'react';

import Layout from '../components/Layout';
import ReviewContent from '../components/ContentReview';

const ContentReview = () => {
  return (
    <Layout>
      <ReviewContent />
    </Layout>
  )
}

export default ContentReview;