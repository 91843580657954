import React from 'react';
import Layout from '../components/Layout';

const Public = ({location}) => {
  return (
    <Layout>
      <h1>Public Page</h1>
      <h2>Static HTML Page</h2>
    </Layout>
  )
}
export default Public;