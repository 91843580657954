import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import AuthNav from './AuthNav';
import PublicNav from './PublicNav';

const Nav = () => {

	return (<>
		<PublicNav />
		<div className="d-flex align-items-center">
			<form className="w-100 me-3">
				<input type="search" className="form-control" placeholder="Search..." aria-label="Search" />
			</form>

			<AuthNav />
		</div>
	</>);
}

export default Nav;