import React, {useState} from 'react';
import Layout from '../components/Layout';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const Poll = ({location}) => {
	const { getAccessTokenSilently } = useAuth0();

	const getPoll = async (e) => {
		e.preventDefault();

		const token = await getAccessTokenSilently();
		return axios.get(process.env.REACT_APP_SERVER_URL+'/api/articles/poll', {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(async ({data}) => {
				console.log(data);
			})
			.catch( error => {
				console.log(error);
			});
	}
  return (
    <Layout>
      <h1>Polling Page</h1>
      <p>Use the button below to poll for articles. Be <i>very careful</i> with this button as its use is metered by NewsAPI.</p>
			<button type="button" className="btn btn-primary" onClick={getPoll}>Poll</button>
			<div></div>
    </Layout>
  )
}
export default Poll;