import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({children}) => {
	return (
			<>
					<Header></Header>
						<main className="flex-shrink-0">
							<div className="container">
								{children}
							</div>
					</main>
					<Footer></Footer>
			</>
	);
}

export default Layout;