import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Popover from 'react-bootstrap/Popover'; 
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

import "./ContentReview.css";

const Fragment = ({ fragment, setRatings }) => {
	const [rating, setRating] = useState({
		category: fragment.ratings[0] ? fragment.ratings[0].category : null,
		rating: fragment.ratings[0] ? fragment.ratings[0].rating : null
	});

	const handleChange = (e) => {
		//update local component state
		if(e.target.name == "category") {
			setRating({
				[e.target.name]: e.target.value,
			})
		} else {
			setRating({ ...rating,
				[e.target.name]: e.target.value
			})
		}
		//update parent component state
		setRatings({ 
			_id: fragment._id,
			category: e.target.name == "category" ? e.target.value : rating.category,
			rating: e.target.name == "rating" ? e.target.value : rating.rating,
		});
	}

	const renderPopoverContent = (props) => {
		return (
			<Popover id="popover-basic" {...props}>
				<Popover.Title as="h3">Classify sentence</Popover.Title>
				<Popover.Content>
					<form>
						<div className="form-check">
							<select className="form-select" aria-label="Classify sentence" value={["fact", "analysis", "other"].includes(rating.category) ? rating.category : ""} onChange={handleChange} name="category">
								<option value="">Classify sentence</option>
								<option value="fact">Fact</option>
								<option value="analysis">Analysis</option>
								<option value="other">Other (e.g., advert.)</option>
							</select>
						</div>
					{ rating.category === "fact" ?
						<div className="form-check">
							<select className="form-select" aria-label="Truthiness" value={ rating.rating && ["0", "1", "2", "3", "4", "5"].includes(rating.rating.toString()) ? rating.rating.toString() : ""} onChange={handleChange} name="rating">
								<option value="">Choose...</option>
								<option value="0">Unproven</option>
								<option value="1">Wholly false</option>
								<option value="2">Partially false</option>
								<option value="3">Mixed</option>
								<option value="4">Partially true</option>
								<option value="5">Wholly true</option>
							</select>
						</div>
					: ""}
					</form>
				</Popover.Content>
			</Popover>
		);
	}

	return (
		<>
			<OverlayTrigger placement="left" trigger="click" rootClose overlay={renderPopoverContent}>
					<Button variant={ (
						( ["analysis", "other"].includes(rating.category) || ( rating.category == "fact" && ["0", "1", "2", "3", "4", "5"].includes(rating.rating ? rating.rating.toString() : "") ))
					) ? "secondary" : "text"}>{fragment.fragment}</Button>
			</OverlayTrigger>
		</>
	);
}

const ReviewContent = ({ todo }) => {
	const [record, setRecord] = useState([]);
	const [disabledSubmit, setDisabledSubmit] = useState(true);
	const [modalShow, setModalShow] = useState(false);
	const { getAccessTokenSilently } = useAuth0();

	//loads initial content
	useEffect(() => {
		setDisabledSubmit(true);

		const getInitialContent = async () => {
			const token = await getAccessTokenSilently();
			const getArrayURI = (window.location.href).split("/");
			const id = getArrayURI[getArrayURI.length-1];
			await axios.get(process.env.REACT_APP_SERVER_URL+'/api/articles/'+id, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
				.then(async ({data}) =>  {
					setRecord(data);
					setDisabledSubmit(unratedElements(data));
				})
				.catch( error => {
					console.log("There was an error handling the submit: ", error);
				});

		}

		getInitialContent();
	}, []);

	const setFragmentRating = ( fragmentRating ) => {
		if(fragmentRating._id){
			const updatedRecord = record;
			const ratingIndex = record.content.findIndex(element => element._id === fragmentRating._id)
			updatedRecord.content[ratingIndex].ratings = [{
				category: fragmentRating.category,
				rating: fragmentRating.rating
			}];
			setRecord(updatedRecord);
			setDisabledSubmit(unratedElements(updatedRecord));
		} else {
		}
	}

	let unratedElements = (record) => {
		const content = record.content;

		let filteredRecords = content.filter( (element) => {
			return (
				element.fragment && element.fragment.length > 0 &&
				element.ratings && 
					(
						element.ratings.length > 0 && !( 
						(
							["analysis", "other"].includes(element.ratings[0].category) || 
							( 
								element.ratings[0].category == "fact" && [
								"0", "1", "2", "3", "4", "5"].includes(element.ratings[0].rating ? 
									element.ratings[0].rating.toString() : 
									""
								)
							) 
						)
					) || 
					element.ratings.length == 0
				)
			)
		});
		return(filteredRecords.length > 0);
	}

	const handleSubmit = async (e) => {
		setDisabledSubmit(true);
		const token = await getAccessTokenSilently();
		const getArrayURI = (window.location.href).split("/");
		const id = getArrayURI[getArrayURI.length-1];

		await axios.post(process.env.REACT_APP_SERVER_URL+'/api/articles/edit/'+id, { content: record.content }, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
					.then(async ({data}) =>  {
						setDisabledSubmit(false);
						setModalShow(true);
					})
					.catch( error => {
						setDisabledSubmit(false);
						console.log("There was an error handling the submit: ", error);
					});
	}

	let ModalSaveComplete = (props) => {
		return (
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header>
					<Modal.Title id="contained-modal-title-vcenter">
						Save Complete
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Your assessment has saved successfully. You can now close this tab or return to modify your ratings.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={props.onHide}>Close</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	return (
		<>
			{ ((record ? record.content : [] ) || []).map(function(fragment, index, arr){
				if(fragment.fragment === "") {} else {
					if(index === (arr.length - 1) ) { //if last item in array
						return (
							<Fragment fragment={fragment} key={index} setRatings={setFragmentRating}/>
						)
					} else {
						return (
							<Fragment fragment={fragment} key={index} setRatings={setFragmentRating}/>
						)
					}
				}
			}) }
				<Button variant="primary" onClick={handleSubmit} disabled={disabledSubmit}>Save</Button>
				<ModalSaveComplete
					show={modalShow}
					onHide={() => setModalShow(false)}
				/>
		</>
	);
}

export default ReviewContent;