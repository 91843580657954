import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Nav from './Nav';

const Header = () => {
	return (
		<header>
			<div className="container-fluid d-grid gap-3 align-items-center" style={{"gridTemplateColumns": "1fr 2fr"}}>
				<Nav />
			</div>
		</header>
	);
}

export default Header;